<!-- eslint-disable radix -->
<template>
  <div>
    <b-card
      v-if="showInterviewSettings"
      border-variant="dark"
      class="text-center"
      header="Interview Format"
    >

      <div
        class="d-flex flex-wrap mb-1"
      >
        <div class="mx-2">
          <b-form-radio
            v-model="formData.interview_type"
            class="mx-2"
            value="physical"
          >In-Person Interview</b-form-radio>
        </div>

        <div class="mx-2">
          <b-form-radio
            v-model="formData.interview_type"
            class="mx-2"
            value="virtual"
          >Virtual Interview</b-form-radio>
        </div>

        <div class="mx-2">
          <b-form-radio
            v-model="formData.interview_type"
            class="mx-2"
            value="speed_interview"
          >Virtual Speed Interview</b-form-radio>
        </div>

        <div
          class="vx-col w-1/2"
        >
          <b-form-radio
            v-model="formData.interview_type"
            class="mx-2"
            :value="null"
          >None</b-form-radio>
        </div>
      </div>

      <div
        v-if="formData.interview_type === 'speed_interview'"
        class="vx-row mb-1"
      >
        <b-form-input
          v-model="formData.interview_duration"
          type="number"
          class=""
          placeholder="How many minutes should the interview last?"
        />
      </div>

      <div
        v-if="formData.interview_type === 'physical'"
        class="vx-row mt-2 mb-1"
      >
        <b-form-input
          v-model="formData.interview_location"
          type="text"
          class="w-full"
          placeholder="What will be the location of the event?"
        />

        <!-- <gmaps-map>
                <gmaps-marker :options="interview_location" />
              </gmaps-map> -->
      </div>

      <div
        v-if="formData.interview_type === 'physical'"
        class="vx-row mt-2 mb-1"
      >
        <b-form-input
          v-model="formData.max_applications"
          type="text"
          class="w-full"
          placeholder="What will be the maximum number of participants for this event?"
        />

        <!-- <gmaps-map>
                <gmaps-marker :options="interview_location" />
              </gmaps-map> -->
      </div>

    </b-card>

    <div v-else>
      <div
        v-if="allowBulkUpload"
        class="d-flex m-1"
      >
        <div class="d-flex ml-1 mt-1">
          <h6
            class="font-weight-bolder"
            style="margin-top: 2px; "
          >
            Bulk Upload
          </h6>
          <b-form-checkbox
            v-model="bulk_upload"
            name="check-button"
            switch
            inline
            class="ml-2 custom-control-primary"
          />
        </div>
        <div
          v-if="bulk_upload"
          class="d-flex ml-1 mb-1"
        >
          <div class="mr-1">
            <b-button
              variant="warning"
              type="filled"
              icon-pack="feather"
              icon="icon-arrow-down-circle"
              @click="downloadJobTemplate"
            >Download Job Template</b-button>
          </div>
          <div class="ml-1">
            <input
              ref="uploadCSV"
              class="hidden"
              type="file"
              accept=".xls,.xlsx,.xlsm"
              @change="uploadBulkJobs"
            >

            <b-button
              id="#button-with-loading"
              class="w-full shadow-lg"
              variant="primary"
              @click="$refs.uploadCSV.click()"
            >Upload File</b-button>
          </div>
        </div>
      </div>

      <div
        v-if="!bulk_upload"
        class="ml-1"
      >
        <div
          v-if="
            !specified_event_id
              && $router.name === 'applicant-tracking-system-edit'
              && $router.name === 'applicant-tracking-system-edit-specific'
          "
          class="mx-1"
        >
          <h6
            class="font-weight-bolder"
            :class="event_id && event_id.length > 0 ? '': 'text-danger'"
          >
            Available Event Groups *
          </h6>
          <EventSearch
            id="event-search"
            v-model="formData.event_id"

            name="event-search"
            :multiple="false"
            :disabled="specified_event_id"
            @event-selected="selectedEvent"
          />

        </div>

        <div>
          <div class="d-flex flex-wrap mt-1">
            <b-col
              v-if="$store.state.auth.ActiveUser.account_type === this.$AccountTypes.EMPLOYER"
              cols="12"
              md="12"
            >
              <h6 class="font-weight-bolder">
                Company Name *
              </h6>
              <b-form-input
                v-model="$store.state.auth.ActiveUser.company_name"
                required
                class="w-full"
                disabled
              />

              <b-row class="mt-1 ">
                <b-form-checkbox
                  v-if="specialCompanyChecks('A')"
                  v-model="published_fields.recruiter"
                  class="ml-1"
                  disabled
                >Publish Company Name</b-form-checkbox>
                <b-form-checkbox
                  v-else
                  v-model="published_fields.recruiter"
                  class="ml-1"
                >Publish Company Name</b-form-checkbox>
              </b-row>
            </b-col>
            <b-col
              class="mt-1 mt-md-0"
              cols="12"
              md="12"
            >

              <h6
                v-if="
                  $store.state.auth.ActiveUser.company_name === 'PersolKelly' || $store.state.auth.ActiveUser.company_name === 'ADECCO PERSONNEL PTE LTD'
                "
                class="font-weight-bolder"
              >
                Job Industry
              </h6>
              <h6
                v-else-if="
                  $store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY
                "
                class="font-weight-bolder"

                :class="formData.company_id ? '': 'text-danger'"
              >
                Client Company Name *
              </h6>

              <v-select
                v-if="
                  $store.state.auth.ActiveUser.company_name === 'PersolKelly' || $store.state.auth.ActiveUser.company_name === 'ADECCO PERSONNEL PTE LTD'
                "
                v-model="formData.industry"
                placeholder="Choose the company industry"
                multiple
                :options="allIndustries"
                label="text"
                class="selectExample w-full"
                :reduce="value => value.value"
                @search="handleInput"
              />

              <CompanySearch
                v-else-if="
                  $store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY
                "
                ref="refCompanyIdSelect"
                id="register-account-company"
                v-model="formData.company_id"
                :validator="true"
                name="register-account-company"
                :multiple="false"
                :placeholder="'Start typing to get company suggestions'"

                @company-selected="selectedCompanies"
              />

              <div
                v-if="
                  $store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY
                "
                class="mt-1"
              >
                <b-form-checkbox
                  v-if="specialCompanyChecks('A')"
                  v-model="published_fields.employer"
                  class=""
                  disabled
                >
                  Publish Client Company Name</b-form-checkbox>
                <b-form-checkbox
                  v-else
                  v-model="published_fields.employer"
                  class=""
                >Publish Client Company Name</b-form-checkbox>
              </div>
            </b-col>
          </div>

          <div class="d-flex flex-wrap mt-1">
            <b-col
              cols="12"
              md="6"
            >
              <h6 class="font-weight-bolder">
                Job Owner Email *
              </h6>
              <b-form-input
                v-model="$store.state.auth.ActiveUser.email_address"
                required
                type="email"
                class="w-full"
                disabled
                placeholder="Job owner email"
              />
            </b-col>
            <b-col
              class="mt-1 mt-md-0"
              cols="12"
              md="6"
            >
              <h6
                class="font-weight-bolder"
                :class="formData.hiring_managers && $checkEmail(formData.hiring_managers).state ? '': 'text-danger'"
              >
                Hiring Manager Email *
              </h6>
              <b-form-input
                v-model="formData.hiring_managers"
                required
                type="email"
                class="w-full"
                :state="formData.hiring_managers ? true: false"
                placeholder="Hiring Manager Email"
              />
            </b-col>
          </div>

          <div class="mx-1 mt-1">
            <h6
              class="font-weight-bolder"
              :class="formData.position ? '': 'text-danger'"
            >
              Job Position *
            </h6>
            <b-form-input
              v-model="formData.position"
              :state="formData.position ? true: false"
              required
              class="w-full"
            />
          </div>
          <div
            v-if="$store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY"
            class="mx-1"
          >
            <b-form-checkbox
              v-model="formData.featured"
              class="mt-1 mb-1"
            >Feature this job in the event listings page</b-form-checkbox>
          </div>

          <div class="mx-1 mt-1">
            <h6
              v-if="specialCompanyChecks('A')"
              class="font-weight-bolder"
            >
              Client Profile
            </h6>
            <b-form-textarea
              v-if="specialCompanyChecks('A')"
              v-model="formData.client_profile"
              rows="4"
              class="w-full"
            />
          </div>

          <div class="mx-1 mt-1">
            <h6
              class="font-weight-bolder"
              :class="formData.vacancies > 0 ? '': 'text-danger'"
            >
              Number of Vacancies *
            </h6>
            <b-form-input
              v-model="formData.vacancies"
              :state="formData.vacancies > 0 ? true: false"
              required
              onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
              class="w-full"
              placeholder=""
            />

            <div class="vx-col mt-1 ">
              <b-form-checkbox
                v-model="published_fields.vacancies"
              >Publish Number of Vacancies</b-form-checkbox>
            </div>
          </div>

          <div class="d-flex flex-wrap mt-1">
            <b-col
              cols="12"
              md="6"
            >
              <h6
                class="font-weight-bolder"
                :class="formData.working_days && formData.working_days.length > 0 ? '': 'text-danger'"
              >
                Working Days *
              </h6>
              <b-form-input
                v-model="formData.working_days"
                :state="formData.working_days && formData.working_days.length > 0"
                required
                class="w-full"
                placeholder="For Example, Monday to Friday"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
              />
            </b-col>

            <b-col
              class="mt-1 mt-md-0"
              cols="12"
              md="6"
            >
              <h6
                class="font-weight-bolder"
                :class="formData.working_time && formData.working_time.length > 0 ? '': 'text-danger'"
              >
                Working Hours *
              </h6>
              <b-form-input
                v-model="formData.working_time"
                required
                :state="formData.working_time && formData.working_time.length > 0"
                class="w-full"
                placeholder="For Example, 9am to 6pm"
                val-icon-danger="icon-x"
                val-icon-pack="feather"
              />
            </b-col>
          </div>

          <div class="mx-1 mt-1">
            <h6
              class="font-weight-bolder"
              :class="job_type ? '': 'text-danger'"
            >
              Job Type *
            </h6>

            <div
              class="row "
            >
              <b-form-radio
                v-model="job_type"
                class="col ml-1"
                value="parttime"
              >Part-time</b-form-radio>
              <b-form-radio
                v-model="job_type"
                class="col ml-1"
                value="fulltime"
              >Full-time</b-form-radio>
            </div>
          </div>

          <div class="mx-1 mt-1">
            <h6
              class="font-weight-bolder"
              :class="checkEmptyEmploymentType ? '': 'text-danger'"
            >
              Employment Type *
            </h6>
            <div class="d-flex justify-content-between flex-wrap">
              <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                <b-form-checkbox
                  v-model="employment_type.internship_traineeship"
                >Internship/Traineeship</b-form-checkbox>
              </div>

              <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                <b-form-checkbox
                  v-model="employment_type.freelance"
                >Freelance</b-form-checkbox>
              </div>

              <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                <b-form-checkbox
                  v-model="employment_type.permanent"
                >Permanent</b-form-checkbox>
              </div>

              <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                <b-form-checkbox
                  v-model="employment_type.contract"
                >Contract</b-form-checkbox>
              </div>

              <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                <b-form-checkbox
                  v-model="employment_type.flexiwork"
                >Flexi-work</b-form-checkbox>
              </div>

              <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                <b-form-checkbox
                  v-model="employment_type.temporary"
                >Temporary</b-form-checkbox>
              </div>

              <div :class="$isMobile() ? 'w-100' : 'mb-1'">
                <b-form-checkbox v-model="employment_type.prn">
                  PRN
                </b-form-checkbox>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <b-col
              class="mt-1"
              cols="12"
              md="3"
            >
              <h6
                class="font-weight-bolder"
                :class="formData.salary_currency ? '': 'text-danger'"
              >
                Currency *
              </h6>
              <v-select
                v-model="formData.salary_currency"
                :class="formData.salary_currency ? '': 'danger-select' "
                placeholder="Choose the salary currency"
                class="w-full"
                :options="all_salary_currencies"
                label="value"
                :reduce="value => value.value"
              />
            </b-col>
            <b-col
              class="mt-1"
              cols="12"
              md="3"
            >
              <h6
                class="font-weight-bolder"
                :class="formData.min_salary ? '': 'text-danger'"
              >
                Minimum Salary *
              </h6>
              <b-form-input

                v-model="formData.min_salary"
                required
                class="w-full"
                type="number"
                placeholder=""
                :state="formData.min_salary > 0 ? true : false"
              />
            </b-col>

            <b-col
              class="mt-1"
              cols="12"
              md="3"
            >
              <h6
                class="font-weight-bolder"
                :class="formData.max_salary ? '': 'text-danger'"
              >
                Maximum Salary *
              </h6>
              <b-form-input
                v-model="formData.max_salary"
                required
                class="w-full"
                type="number"
                placeholder=""
                :state="!showSalaryWarning()"
              />
              <b-form-invalid-feedback
                class="ml-1"
                tooltip
              >
                {{ parseInt(formData.min_salary) > parseInt(formData.max_salary)
                  ? 'The minimum salary cannot be greater than the maximum salary'
                  : 'You need to add a valid maximum salary.' }}
              </b-form-invalid-feedback>

            </b-col>

            <b-col
              class="mt-1"
              cols="12"
              md="3"
            >
              <h6
                class="font-weight-bolder"
                :class="formData.salary_cycle ? '': 'text-danger'"
              >
                Salary Payment Cycle *
              </h6>
              <v-select
                v-model="formData.salary_cycle"
                :class="formData.salary_cycle ? '': 'danger-select' "
                placeholder="Choose the salary cycle"
                class="w-full"
                :options="all_salary_cycles"
                label="value"
                :reduce="value => value.value"
              />
            </b-col>
          </div>

          <div class="mt-2 ml-1">
            <b-form-checkbox
              v-model="published_fields.salary"
            >Publish Salary</b-form-checkbox>
          </div>

          <div class="vx-row w-100">
            <h6
              class="font-weight-bolder mx-1 mt-1"
              :class="formData.working_location_type ? '': 'text-danger'"
            >
              Mode of work *
            </h6>
            <div class="d-flex flex-wrap justify-content-between mx-1">
              <b-form-radio
                v-model="formData.working_location_type"
                class="vx-col w-1/3"
                value="remote_only"
              >Remote Only</b-form-radio>
              <b-form-radio
                v-model="formData.working_location_type"
                class="vx-col w-1/3"
                value="hybrid"
              >Hybrid (Remote and Office)</b-form-radio>
              <b-form-radio
                v-model="formData.working_location_type"
                class="vx-col w-1/3"
                value="physical"
              >Office Only</b-form-radio>
            </div>

            <b-row
              v-if="
                formData.working_location_type === 'physical' ||
                  formData.working_location_type === 'hybrid'
              "
              class="w-100 mx-0"
            >
              <b-col
                class="mt-1"
                cols="12"
                md="6"
              >
                <h6 class="font-weight-bolder">
                  Working Location Address
                </h6>
                <b-form-input
                  v-model="formData.working_location_address"
                  required
                  class="w-full"
                  :danger="formData.working_location_address > 1 ? false : true"
                  danger-text="You need to add a valid address."
                  val-icon-danger="icon-x"
                  val-icon-pack="feather"
                />
              </b-col>

              <b-col
                class="mt-1"
                cols="12"
                md="6"
              >
                <h6 class="font-weight-bolder">
                  Working Location State
                </h6>
                <b-form-input
                  v-model="formData.working_location_state"
                  required
                  class="w-full"
                  :danger="formData.working_location_state > 1 ? false : true"
                  danger-text="You need to add a valid state."
                  val-icon-danger="icon-x"
                  val-icon-pack="feather"
                />
              </b-col>

              <b-col
                class="mt-1"
                cols="12"
                md="6"
              >
                <h6 class="font-weight-bolder">
                  Working Location Country
                </h6>

                <v-select
                  v-model="formData.working_location_country"
                  placeholder="Choose the country where the job is located in"
                  autocomplete
                  class="w-full"
                  :options="allCountries"
                  label="value"
                  :reduce="value => value.value"
                />
              </b-col>

              <b-col
                class="mt-1"
                cols="12"
                md="6"
              >
                <h6 class="font-weight-bolder">
                  Working Location Postal Code
                </h6>
                <b-form-input
                  v-model="formData.working_location_postal_code"
                  required
                  class="w-full"
                  :danger="
                    formData.working_location_postal_code > 1 ? false : true
                  "
                  danger-text="You need to add a valid postal code."
                  val-icon-danger="icon-x"
                  val-icon-pack="feather"
                />
              </b-col>
            </b-row>
          </div>

          <div class="d-flex flex-wrap">
            <b-col
              class="vx-col w-1/2 mt-1"
              cols="12"
              md="6"
            >
              <h6 class="font-weight-bolder">
                Position Level
              </h6>
              <v-select
                v-model="formData.position_level"
                placeholder="Select the position level"
                class="w-full"
                :options="all_position_levels"
                label="value"
                :reduce="value => value.value"
              />
            </b-col>

            <b-col
              class="mt-1"
              cols="12"
              md="6"
            >
              <h6 class="font-weight-bolder">
                Minimum Qualification Level
              </h6>
              <v-select
                v-model="formData.min_qualification"
                placeholder="Choose the qualification level"
                class="w-full"
                :options="all_qualification_levels"
                label="value"
                :reduce="value => value.value"
              />
            </b-col>
          </div>

          <div class="d-flex flex-wrap">
            <b-col
              class="mt-1"
              cols="12"
              md="6"
            >
              <h6 class="font-weight-bolder">
                Minimum Years Of Experience
              </h6>
              <b-form-input
                v-model="formData.min_yoe"
                required
                type="number"
                placeholder="Minimum years of experience required(Only numbers allowed)"
                class="w-full"
              />
            </b-col>

            <b-col
              class="mt-1"
              cols="12"
              md="6"
            >
              <h6 class="font-weight-bolder">
                Maximum Years Of Experience (If there is no maximum years of
                experience, leave it as blank)
              </h6>
              <b-form-input
                v-model="formData.max_yoe"
                required
                type="number"
                placeholder="Maximum years of experience required(Only numbers allowed)"
                class="w-full"
              />
            </b-col>
          </div>

          <div class="mx-1 mt-1">
            <h6
              v-if="specialCompanyChecks('A')"
              class="font-weight-bolder"
            >
              How long should the job last before expiry?
            </h6>
            <div
              v-if="specialCompanyChecks('A')"
              class="d-flex flex-wrap mt-1"
            >
              <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                <b-form-radio
                  v-model="job_expiry"
                  class="w-full"
                  value="7"
                >1 Week</b-form-radio>
              </div>

              <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                <b-form-radio
                  v-model="job_expiry"
                  class="w-full"
                  value="14"
                >2 Weeks</b-form-radio>
              </div>

              <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                <b-form-radio
                  v-model="job_expiry"
                  class="w-full"
                  value="30"
                >1 Month</b-form-radio>
              </div>

              <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                <b-form-radio
                  v-model="job_expiry"
                  class="w-full"
                  value="60"
                >2 Months</b-form-radio>
              </div>

              <div class="vx-col md:w-1/3 w-full mb-0 mb-md-2 mx-1">
                <b-form-radio
                  v-model="job_expiry"
                  class="w-full"
                  value="90"
                >3 Months</b-form-radio>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap w-100">
            <b-col
              class="mt-1"
              cols="12"
              md="6"
            >
              <h6
                class="font-weight-bolder"
                :class="formData.description ? '': 'text-danger'"
              >
                Job Description *
              </h6>
              <b-form-textarea
                v-model="formData.description"
                rows="4"
                class="w-full"
                :class="formData.description ? '': 'danger-select' "
                :label="
                  formData.description
                    ? ''
                    : 'The job description needs to have at least 10 characters.'
                "
              />
            </b-col>
            <b-col
              class="mt-1"
              cols="12"
              md="6"
            >
              <h6
                class="font-weight-bolder"
                :class="formData.requirements ? '': 'text-danger'"
              >
                Job Requirements *
              </h6>
              <b-form-textarea
                v-model="formData.requirements"
                rows="4"
                class="w-full"
                :class="formData.requirements ? '' : 'danger-select'"
                :label="
                  formData.requirements
                    ? ''
                    : 'The job requirements needs to have at least 10 characters.'
                "
              />
            </b-col>
          </div>

          <div class="mx-1 mt-1">
            <h6 class="font-weight-bolder">
              Job Benefits
            </h6>
            <b-form-textarea
              v-model="formData.benefits"
              rows="4"
              class="w-full"
              label="List all the job benefits below"
            />
          </div>

          <div class="mx-1 mt-1">
            <h6 class="font-weight-bolder">
              Required Skillsets (Press Enter after typing each skillset)
            </h6>
            <b-form-tags
              v-model="formData.required_skills"
              input-id="tags-basic"
              class="w-full"
              placeholder="Click enter after adding each required skill"
            />

          </div>

          <div class="mx-1 mt-1">
            <h6 class="mt-1 font-weight-bolder">
              Customised Screening Questions (Press Enter after typing each skillset)
            </h6>
            <b-form-tags
              v-model="formData.questions"
              input-id="tags-basic"
              class="w-full"
              placeholder="Click enter after adding each question"
            />
          </div>
          <div class="mx-1 mt-1">
            <h6

              class="mt-1 font-weight-bolder"
            >
              Brief Description Of Type Of Candidates You Are Looking For
            </h6>
            <b-form-textarea
              v-model="formData.applicant_description"
              rows="4"
              class="w-full"
              :label="
                formData.applicant_description
                  ? ''
                  : 'The brief description needs to have at least 10 characters.'
              "
            />
          </div>

        </div>

        <b-alert
          class="h-auto my-4"
          :active="notify_employer"
          variant="warning"
          icon-pack="feather"
          icon="icon-info"
        >
          <p class="font-bold">
            Note
          </p>
          <span>{{ notify_text }} </span>
        </b-alert>
      </div>
    </div>

    <div class="vx-row my-3">

      <div
        v-if="
          $route.name !== 'applicant-tracking-system-edit'
            && $route.name !== 'applicant-tracking-system-edit-specific'
        "
        class="mx-auto float-left"
      >
        <b-button
          variant="primary"
          class="mr-3 w-64"
          @click="showInterviewSettings = false"
        >Back</b-button>

      </div>
      <div
        v-if="
          $route.name !== 'applicant-tracking-system-edit'
            && $route.name !== 'applicant-tracking-system-edit-specific'
        "
        class="mx-auto float-right"
      >

        <b-button
          v-if="operation === 'write'"
          variant="primary"
          class="mr-3 w-64"
          @click="addJob"
        >Save Changes</b-button>
        <b-button
          v-else
          variant="primary"
          class="mr-3 w-64"
          @click="editJob"
        >Save Changes</b-button>
      </div>
    </div>
  </div>
</template>
<script>
// Import FilePond plugins
import { orderBy } from 'lodash'
import {
  allIndustries,
  allCountries,
  highestQualifications,
} from '@/constants/datastore'
import vSelect from 'vue-select'
import CompanySearch from '@/components/CompanySearch.vue'
import EventSearch from '@/components/jobs/EventSearch.vue'

export default {
  name: 'JobForm',
  components: {
    vSelect,
    CompanySearch,
    EventSearch,

  },
  props: {
    wizardRef: Object,
    allowBulkUpload: { type: Boolean, default: false },
    eventId: { type: String, default: null },
    jobId: { type: String, default: null },
    showInterviewInput: { type: Boolean, default: false },
  },
  data() {
    return {
      formData: {
        position: null,
        client_profile: null,
        salary_currency: null,
        salary_cycle: null,
        min_salary: 0,
        max_salary: 0,
        working_days: null,
        working_time: null,
        working_location_type: null,
        working_location_address: null,
        working_location_state: null,
        working_location_country: 'Singapore',
        working_location_postal_code: null,
        max_applications: null,
        interview_location: null,
        interview_type: null,
        interview_duration: null,
        position_level: null,
        min_qualification: null,
        min_yoe: null,
        max_yoe: null,
        description: null,
        requirements: null,
        benefits: null,
        questions: [],
        required_skills: null,
        industry: [],
        publication_status: 'pending',
        published_fields: [],
        hiring_managers: null,
        vacancies: 0,
        featured: false,
        applicant_description: '',
      },
      published_fields: {
        vacancies: true,
        salary: false,
        employer: false,
        recruiter: true,
      },

      bulk_upload: false,
      job_type: null,
      hiring_managers: null,
      employment_type: {
        freelance: false,
        internship_traineeship: false,
        flexiwork: false,
        permanent: false,
        contract: false,
        temporary: false,
        prn: false,
      },
      all_position_levels: [
        { text: 'Senior Management', value: 'Senior Management' },
        { text: 'Middle Management', value: 'Middle Management' },
        { text: 'Manager', value: 'Manager' },
        { text: 'Professional', value: 'Professional' },
        { text: 'Senior Executive', value: 'Senior Executive' },
        { text: 'Executive', value: 'Executive' },
        { text: 'Junior Executive', value: 'Junior Executive' },
        { text: 'Non-Executive', value: 'Non-Executive' },
        { text: 'Fresh/Entry Level', value: 'Fresh/Entry Level' },
      ],

      all_salary_cycles: [
        //
        { text: 'Per Annum', value: 'Yearly' },
        { text: 'Per Month', value: 'Monthly' },
        { text: 'Per Week', value: 'Weekly' },
        { text: 'Per Day', value: 'Daily' },
        { text: 'Per Hour', value: 'Hourly' },
      ],
      salary_currency: 'SGD',
      all_salary_currencies: [
        { text: 'SGD', value: 'SGD' },
        { text: 'USD', value: 'USD' },
        { text: 'EUR', value: 'EUR' },
        { text: 'GBP', value: 'GBP' },
      ],
      showInterviewSettings: false,
      allEvents: [],
      isLoading: false,
      notify_employer: false,
      notify_text: '',
      all_qualification_levels: null,
      required_skillset_options: null,
      operation: 'write',
      allCountries: [],
      event_id: null,
      specified_event_id: false,
    }
  },
  computed: {
    changedEventID() {
      return this.formData.event_id
    },
    checkEmptyEmploymentType() {
      return Object.entries(this.employment_type).filter(([key, value]) => {
        if (value === true) {
          return key[0]
        }
      })
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(value) {
        this.$emit('form-data', this.formData)
      },
    },
    published_fields: {
      deep: true,
      handler(value) {
        const output = Object.keys(this.published_fields).filter(key => {
          if (this.published_fields[key] === true) {
            return key
          }
        })
        this.$set(this.formData, 'published_fields', output)
        // this.$emit('form-data', this.formData)
      },
    },
    employment_type: {
      deep: true,
      handler(value) {
        const output = Object.keys(this.employment_type).filter(key => {
          if (this.employment_type[key] === true) {
            return key
          }
        })
        this.$set(this.formData, 'employment_type', output)
        // this.$emit('form-data', this.formData)
      },
    },
    job_type(val, oldVal) {
      this.formData.job_type = val 
    },
    showInterviewSettings(val, oldVal) {
      if (oldVal === true) {
        // repopulate Client Company Name
        const { company_id } = this.formData
        setTimeout(() => {
          this.repopulateInputWithFormData()
        }, 100)

        setTimeout(() => {
          this.formData.company_id = company_id
        }, 120)
      }
    },
    showInterviewInput(val, oldVal) {
      this.showInterviewSettings = val
    }
  },
  created() {
    this.all_industries = orderBy(allIndustries, ['value'], ['asc'])
    this.all_qualification_levels = orderBy(highestQualifications, ['value'], ['asc'])
    this.allCountries = allCountries

    if (this.jobId) {
      this.getJob()
    }
  },
  mounted() {
    this.event_id = this.$route.params.event_id
    if (this.event_id && this.event_id.includes('-N')) {
      this.event_id = this.event_id.replace('-N', '')
    }

    if (this.eventId) {
      this.formData.event_id = this.eventId
      this.specified_event_id = true
    }
  },
  methods: {
    getJob() {
      this.isLoading = true
      let url = ''

      if (['applicant-tracking-system-edit', 'applicant-tracking-system-edit-specific'].includes(this.$route.name)) {
        url = `/api/ats-job/${this.jobId}`
      } else {
        url = `/api/job/${this.jobId}`
      }
      this.$http
        .get(url)
        .then(response => {
          const { success, output } = response.data
          this.$set(this, 'formData', output)

          this.repopulateInputWithFormData()

          this.hiring_managers = output.hiring_managers
          this.job_type = output.job_type

          this.operation = 'edit'

          this.isLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
    repopulateInputWithFormData() {
      // populate Client Company Name
      this.$refs.refCompanyIdSelect.getEmployers(this.formData.company_name, false)
      const company = {
        id: this.formData.company_id,
        company_name: this.formData.company_name,
        company_uen_no: this.formData.company_uen_no,
      }
      this.$refs.refCompanyIdSelect.selected_companies = company

      if (this.$isEmpty(this.formData.published_fields)) {
        this.formData.published_fields = []
      }
      Object.entries(this.published_fields).forEach(([field, value]) => {
        if (this.formData.published_fields.includes(field)) {
          this.published_fields[field] = true
        } else {
          this.published_fields[field] = false
        }
      })
      if (this.$isEmpty(this.formData.employment_type)) {
        this.formData.employment_type = []
      }
      Object.entries(this.employment_type).forEach(([field, value]) => {
        if (this.formData.employment_type.includes(field)) {
          this.employment_type[field] = true
        } else {
          this.employment_type[field] = false
        }
      })
    },

    selectedCompanies(company) {
      // this.formData.company_id = company.id
      this.$set(this.formData, 'company_id', company.id)
    },
    selectedEvent(event) {
      this.formData.event_id = event.id
      this.event_id = event.id
    },
    showSalaryWarning() {
      let result = false
      if (this.formData.max_salary === 0) {
        result = true
      }

      if (parseInt(this.formData.min_salary) > parseInt(this.formData.max_salary)) {
        result = true
      }
      return result
    },
    checkFilledFields() {
      let proceed = true
      const message = ''

      this.formData.published_fields = Object.keys(this.published_fields).filter(key => {
        if (this.published_fields[key] === true) {
          return key
        }
      })

      this.formData.hiring_managers = this.hiring_managers
      if (this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.EMPLOYER) {
        this.formData.company_id = this.$store.state.auth.ActiveUser.company_id
      }
      this.formData.job_type = this.job_type

      this.formData.employment_type = Object.keys(this.employment_type).filter(key => {
        if (this.employment_type[key] === true) {
          return key
        }
      })
      const unfilledCols = this.$el.getElementsByClassName('text-danger')
      if (unfilledCols.length > 0) {
        proceed = false
        const element = unfilledCols[0]
        element.scrollIntoView()
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Missing Job Data',
              icon: 'AlertCircleIcon',
              text: 'Please fill in the required values for the highlighted fields.',
              variant: 'danger',
              bodyClass: 'text-break',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }

      return proceed
    },
    addJob() {
      if (this.specified_event_id) {
        this.formData.publication_status = 'draft'
      }

      const result = this.checkFilledFields()

      if (result) {
        if (this.bulk_upload) {
          if (!this.file_upload) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Missing Job Data',
                  icon: 'AlertCircleIcon',
                  text: 'Please upload the excel file containing the jobs before proceeding.',
                  variant: 'danger',
                  bodyClass: 'text-break',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else if (!this.showInterviewSettings) {
            this.showInterviewSettings = true
          } else {
            this.confirmUpload()
          }
        } else {
          if (!this.showInterviewSettings) {
            this.showInterviewSettings = true
          } else {
            this.serverAddJob(result)
          }
        }
      }
    },
    serverAddJob(result) {
      if (result) {
        this.$http
          .post('/api/job/event', this.formData)
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Event Manager',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              if (this.specified_event_id) {
                this.$emit('back-group-add-job', false)
              } else {
                this.$router.push({ name: 'manage-events' })
              }
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Event Manager',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {
            this.$handleErrorResponse(error)
          })
      }
    },
    editJob() {
      const result = this.checkFilledFields()

      if (result) {
        if (this.bulk_upload) {
          if (!this.file_upload) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Missing Job Data',
                  icon: 'AlertCircleIcon',
                  text: 'Please upload the excel file containing the jobs before proceeding.',
                  variant: 'danger',
                  bodyClass: 'text-break',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else if (!this.showInterviewSettings) {
            this.showInterviewSettings = true
          } else {
            this.confirmUpload()
          }
        } else {
          if (!this.showInterviewSettings) {
            this.showInterviewSettings = true
          } else {
            this.serverEditJob(result)
          }
        }
      }
    },
    serverEditJob(result) {
      if (result) {
        this.$http
          .put('/api/job/event', this.formData)
          .then(response => {
            const { success, output } = response.data
            if (success) {
              this.$toastSuccess('Event Manager', response.data.message)

              this.$router.push({ name: 'manage-events', params: {event_id: output.event_id} })
            } else {
              this.$toastDanger('Event Manager', response.data.message)
            }
          })
          .catch(error => {
            this.$handleErrorResponse(error)
          })
      }
    },

    editEvent() {
      const result = this.checkFilledFields()
      const joinEventID = this.$route.params.event_id

      if (result) {
        const all_data = this.extract_values()
        all_data.data_id = joinEventID && joinEventID.length > 2
          ? joinEventID
          : this.item_id
        all_data.type = this.operation

        this.$http
          .put('/api/events', { data: all_data })
          .then(response => {
            if (this.operation === 'update') {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Event Manager',
                    icon: 'AlertCircleIcon',
                    text:
                      joinEventID && joinEventID.length > 2
                        ? 'Your job has been updated successfully'
                        : 'Your event has been updated successfully',
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Employment Data Deletion',
                    icon: 'AlertCircleIcon',
                    text: 'Data deleted successfully!',
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
            if (this.new_event_category !== 'group') {
              if (joinEventID && joinEventID.endsWith('_pending')) {
                window.location.href = `/events-manager/pending-jobs/${this.selected_event_group}`
              } else {
                this.$router.push('/events-manager/events')
              }
            } else {
              // this.external_platform_jobs = response.data.platform_jobs;
              // this.all_selected_events = response.data.all_selected_events;

              this.external_platform_jobs = response.data.all_selected_events

              this.all_selected_events = response.data.all_selected_events

              this.participating_events_data = response.data.platform_jobs

              if (this.all_selected_companies.length > 0) {
                // this.participating_events_data =
                //   this.external_platform_jobs.filter((job) =>
                //     this.all_selected_events.includes(job._id)
                //   );
              }

              // this.$refs.wizard.changeTab(0, 1);
              this.$refs.wizard.nextTab()
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Event Manager',
                    icon: 'AlertCircleIcon',
                    text: 'You can select the jobs that will be added to this event.',
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      }
    },

    cleanRedirectionLink() {
      if (this.formData.redirection_link) {
        this.formData.redirection_link = this.formData.redirection_link
          .toLowerCase()
          .replace('https://', '')
          .replace('https://', '')
        if (this.formData.redirection_link.length > 0) {
          this.formData.redirection_link = `https://${this.formData.redirection_link}`
        } else {
          this.formData.redirection_link = null
        }
      } else {
        this.formData.redirection_link = null
      }

      return this.formData.redirection_link
    },

    specialCompanyChecks(group_type) {
      if (group_type === 'A') {
        const companies = [
          'DYNAMIC HUMAN CAPITAL PTE. LTD.',
          'PersolKelly',
          'FASTCO PTE. LTD.',
          'ELITEZ & ASSOCIATES PTE. LTD.',
          'ELITEZ PTE. LTD.',
          'ADECCO PERSONNEL PTE LTD',
        ]

        if (companies.includes(this.$store.state.auth.ActiveUser.company_name)) {
          return true
        }
      }

      return false
    },

    // bulk upload job
    downloadJobTemplate() {
      const template = 'job_template.xlsx'

      const templateUrl = `${this.$bucketUrl}/templates/${template}`

      fetch(templateUrl)
        .then(response => response.blob())
        .then(blob => {
          const fileURL = window.URL.createObjectURL(blob)
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', template)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(console.error)
    },
    uploadBulkJobs(input) {
      this.file_upload = input
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'File Attachment',
            icon: 'AlertCircleIcon',
            text: 'File attached successfully.',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    showBulk() {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'File Upload',
            icon: 'AlertCircleIcon',
            text: 'Click `Upload File` to upload a new file.',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      )
      this.file_upload = ''
      this.showInterviewSettings = false
    },
    confirmUpload() {
      const input = this.file_upload
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'File Upload Status',
            icon: 'AlertCircleIcon',
            text: 'File upload in progress.',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      )
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {}
        const fd = new FormData()
        fd.append('file', input.target.files[0])
        const bulkInterviewFormat = {
          interview_type: this.formData.interview_type,
          interview_duration: this.formData.interview_duration,
          interview_location: this.formData.interview_location,
          max_applications: this.formData.max_applications,
        }

        this.$http
          .post(`/api/upload-file-bulk-jobs/${this.event_id}?params=${JSON.stringify(bulkInterviewFormat)}`, fd)
          .then(response => {
            if (response.data.errors.length > 0) {
              this.$bvModal
                .msgBoxConfirm(response.data.errors.join('\n'), {
                  title: 'Errors detected',
                  size: 'sm',
                  okVariant: 'danger',
                  okTitle: 'Upload New File',
                  cancelTitle: 'Cancel',
                  cancelVariant: 'outline-secondary',
                  hideHeaderClose: false,
                  centered: true,
                })
                .then(value => {
                  if (value) {
                    this.showBulk()
                  }
                })
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'File Upload',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.$emit('back-group-add-job', false)
              // this.$refs.uploadCSV.value = "";
              this.file_upload = ''
            }
          })
          .catch(error => {
            
          })
      }
    },
    cancelUpload() {
      this.file_upload = ''
      this.$refs.uploadCSV.value = ''
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'File Upload Status',
            icon: 'AlertCircleIcon',
            text: 'File upload cancelled.',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },

  },
}
</script>
